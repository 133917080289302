import s from './app.scss';
import 'whatwg-fetch';

const toUrlFormat = (nodeName = '') => {
  return nodeName
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase();
};

(async function() {
  const headers = new Headers({
    Accept: 'application/json',
    Cookie: document.cookie,
  });

  const translations = require(`./assets/locale/messages/messages_en.json`); //config.language
  const logoImage = require(`./assets/wix-developers.svg`);
  const newLogoImage = require(`./assets/wix-developers-new-logo.svg`);
  const baseUrl = 'https://dev.wix.com/dc3/';

  function onClick(e, tabName) {
    const img = new Image();
    img.src = `https://frog.wix.com/developer-center?src=96&evid=12&pageName=${
      window.location
    }&tabName=${toUrlFormat(tabName)}`;
  }

  function onHoverOnUserDetails() {
    const img = new Image();
    img.src = `https://frog.wix.com/developer-center?src=96&evid=17&pageName=${window.location}`;
  }

  function onClickOnUserDetails(e, menuClick) {
    const img = new Image();
    img.src = `https://frog.wix.com/developer-center?src=96&evid=18&pageName=${
      window.location
    }&menuClick=${toUrlFormat(menuClick)}`;
  }

  const getBaseUrl = () => {
    return (
      (window.config &&
        window.config.dev &&
        window.config.dev.__HEADER_BASEURL__) ||
      baseUrl
    );
  };

  const getXWixRoute = () => {
    const params = new URLSearchParams(window.location.search);
    const xWixRoute = params.get('x-wix-route');
    return xWixRoute || '';
  };

  const getAppId = () => {
    const urlArr = window.location.href.split('/');
    const indexOfMyApps = urlArr.indexOf('my-apps');
    let appId = '';
    if (indexOfMyApps >= 0) {
      appId = urlArr[indexOfMyApps + 1];
    }
    return appId || '';
  };

  const links = {
    home: 'https://dev.wix.com',
    myApps: `${getBaseUrl()}my-apps/`,
    support: 'https://devforum.wix.com',
    apiReference: 'https://dev.wix.com/api/rest/getting-started',
    devCenterUrl: 'https://dev.wix.com/dc3',
    market: 'https://www.wix.com/app-market',
    signin:
      'https://users.wix.com/signin?loginDialogContext=login&referralInfo=HEADER&postLogin=https:%2F%2Fdev.wix.com%2Fdc3%2Fmy-apps&postSignUp=https:%2F%2Fdev.wix.com%2Fdc3%2Fmy-apps',
    developerAccount:
      'https://dev.wix.com/apps/developer-account/developer-info',
    user: `${getBaseUrl()}api/user-details`,
    logout: 'https://www.wix.com/signout?redirectTo=https://dev.wix.com',
    oldDevcenter: 'https://dev.wix.com/_api/dev-center-server/v1/apps',
    permissionCatalog: 'https://bo.wix.com/wow/permissions/permission-catalog',
  };

  const defaultUserDetails = {
    photo:
      'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiI+CiAgICA8ZGVmcz4KICAgICAgICA8cmVjdCBpZD0iYSIgd2lkdGg9IjM2IiBoZWlnaHQ9IjM2IiByeD0iMTgiLz4KICAgIDwvZGVmcz4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPG1hc2sgaWQ9ImIiIGZpbGw9IiNmZmYiPgogICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNhIi8+CiAgICAgICAgPC9tYXNrPgogICAgICAgIDx1c2UgZmlsbD0iI0RBRUZGRSIgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYSIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNCMUQ5RjciIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTMwIDM1YzAtNS4xMy0zLjIxOC05LjUwNy03Ljc0NS0xMS4yMjRhOCA4IDAgMSAwLTguNTEgMEM5LjIxOCAyNS40OTMgNiAyOS44NzEgNiAzNXYxaDI0di0xeiIgbWFzaz0idXJsKCNiKSIvPgogICAgPC9nPgo8L3N2Zz4K',
    userName: 'Unavailable',
    first: '',
    last: '',
    isWixUser: true,
  };

  function getCookie(name) {
    const cookie = document.cookie.match(
      new RegExp('(^|;\\s*)' + name + '=([^;]*)')
    );
    return cookie && cookie[2];
  }

  async function getOldAppsFromDevCenter() {
    return await fetch(links.oldDevcenter, { headers, credentials: 'include' })
      .then((response) => response.json())
      .catch(() => []);
  }

  async function getUser() {
    if (getBaseUrl().includes('localhost')) {
      return Promise.resolve(defaultUserDetails);
    }

    return await fetch(links.user, { headers, credentials: 'include' })
      .then((response) => response.json())
      .then((data) => data.user)
      .catch(() => defaultUserDetails);
  }

  async function getLoggedInMarkup() {
    const oldApps = await getOldAppsFromDevCenter();
    const user = await getUser();
    const profileImage = user && user.photo;
    const appId = getAppId();
    const xWixRoute = getXWixRoute();

    const userName =
      user && user.first !== null && user.last !== null
        ? `${user.first} ${user.last}`
        : user && user.userName;
    return `<div>

<label for="userDetails">
        <ul class=${s.userDetailsNewStyle} data-bi-hover-user-profile="">
        <li class=${s.userInfoNewStyle} data-hook="user-image">
            ${
              profileImage
                ? `<img class=${s.profileImageNewStyle} alt="profile-image" src=${profileImage} />`
                : ''
            }
            ${
              userName
                ? `<div class=${s.userNameContainer}><span class=${s.userNameNewStyle} >${userName}</span><span class=${s.navigationArrowNewStyle}>&rsaquo;</span></div>`
                : ''
            }</li>
            <li class=${s.userActionWrapper}>
                <input type="checkbox" id="userDetails" class=${s.toggle} />
                <ul class=${s.userActionNewStyle}>     
                    <li data-hook="logout"><a href=${
                      links.logout
                    } data-bi-click-user-profile-actions="">${
      translations['logout']
    }</a></li>    
                </ul>
            </li>
        </ul></label></div>`;
  }

  async function renderMarkup() {
    let isWixUser = false;
    const xWixRoute = getXWixRoute();
    if (getCookie('wixClient')) {
      const user = await getUser();
      isWixUser = user && user.isWixUser;
    }
    return `
<a class=${s.logoWrapperNewStyle} href=${links.home}><img class=${
      s.logoNewStyle
    } 
  src=${newLogoImage}/></a>
        <input type="checkbox" id="mobileMenuToggle" class=${
          s.mobileMenuToggle
        } />
        <label class=${s.mobileTrigger} for="mobileMenuToggle" class=${
      s.mobileIcon
    }>
            <span class=${s.stripe} ></span>
            <span class=${s.stripe}></span>
            <span class=${s.stripe}></span>
        </label>
<nav class=${s.navigation}>
                    <ul class=${s.navigationLinksNewStyle}>
                        
                        <li class=${
                          s.navLinkNewStyle
                        }><a data-selected="${location.href.startsWith(
      links.devCenterUrl
    )}" href=${links.myApps}${
      xWixRoute ? `?x-wix-route=${xWixRoute}` : ''
    } data-bi-action-click="">${translations['menu_my_apps']}</a></li>
                        <li class=${
                          s.navLinkNewStyle
                        }><a data-selected="${location.href.startsWith(
      links.apiReference
    )}" href=${links.apiReference} target="_blank" data-bi-action-click="">${
      translations['menu_api_reference']
    }</a> </path> </svg></li>
    <li class=${s.navLinkNewStyle}><a data-selected="${location.href.startsWith(
      links.support
    )}" href=${links.support} data-bi-action-click="">${
      translations['menu_docs']
    }</a></li>
                        <li class=${s.navLinkNewStyle}><a href=${
      links.market
    } target="_blank" data-bi-action-click="">${
      translations['menu_app_market']
    }</a></li>
    
                        
             ${
               isWixUser
                 ? `<li class=${s.navLinkNewStyle}><a href=${
                     links.permissionCatalog
                   } target="_blank" data-bi-action-click="">${
                     isWixUser ? translations['menu_permission_catalog'] : ''
                   }</a></li>`
                 : ''
             }     
                        
                    </ul>
                    <div class=${s.userDetailsMenu} id="dc-user-details">
                    </div>
                </nav>`;
  }

  let header = document.getElementsByTagName('header');
  const firstBodyChild = document.getElementsByTagName('body')[0].children[0];
  if (header.length === 0) {
    header = document.createElement('header');
  } else {
    header = header[0];
  }
  header.setAttribute('data-hook', 'new-header');
  header.innerHTML = await renderMarkup();
  header.classList.add(s.headerNewStyle);
  document.getElementsByTagName('body')[0].insertBefore(header, firstBodyChild);
  document.querySelectorAll('[data-bi-action-click]').forEach((tag) => {
    tag.addEventListener('click', (e) => {
      onClick(e, tag.innerText);
      return true;
    });
  });

  const userDetails = document.getElementById('dc-user-details');
  if (getCookie('wixClient')) {
    const loggedState = await getLoggedInMarkup();
    userDetails.innerHTML = loggedState;
    document
      .querySelector('[data-bi-hover-user-profile]')
      .addEventListener('mouseenter', onHoverOnUserDetails);
    document
      .querySelectorAll('[data-bi-click-user-profile-actions]')
      .forEach((tag) => {
        tag.addEventListener('click', (e) => {
          onClickOnUserDetails(e, tag.innerText);
          return true;
        });
      });
  } else {
    userDetails.setAttribute(
      'style',
      'display: flex; align-items: center; justify-content: center;'
    );
    userDetails.innerHTML = `<a class=${s.signInNewStyle} href=${links.signin}>${translations['signin']}</a>`;
  }
})();
